import React, { useEffect, useState } from "react";

import * as actions from "../../data/Dashboard/dashBoardActions";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import SmartSelectComponent from "./Components/SmartSelectComponent";
import Translate from "translate-components";
const ProgressDashboard = () => {
  const organizations = useSelector(
    (state: RootStateOrAny) => state.dashboard.organizations
  );
  const leaders = useSelector(
    (state: RootStateOrAny) => state.dashboard.leaders
  );
  const assignedCoursesProgress = useSelector(
    (state: RootStateOrAny) => state.dashboard.assignedCoursesProgress
  );

  const instituteRoles = useSelector(
    (state: RootStateOrAny) => state.instituteRole.instituteRoles
  );
  const [selectedOrganization, setSelectedOrganization] = useState(0);
  const [selectedLeader, setSelectedLeader] = useState(0);
  const [filter, setFilter] = useState("");
  const [filteredList, setFilteredList] = useState<any>([]);
  const [displayLeaders, setDisplayLeaders] = useState<any>([]);

  const [preFilter, setPreFilter] = useState("");
  const dispatch = useDispatch();
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);

  const getOrganizations = () => {
    if (userSession && userSession.isLoggedIn())
      dispatch(
        actions.getOrganizationByAssigner(
          userSession?.userId,
          process.env.REACT_APP_SUPPORTED_SYSTEM
        )
      );
  };

  const getCoursesProgress = () => {
    dispatch(actions.getCoursesProgress(selectedOrganization, selectedLeader));
  };

  //Effects Methods

  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    setFilteredList([]);
    setDisplayLeaders([]);
    if (selectedOrganization != 0) {
      dispatch(actions.getLeadersByOrganization(selectedOrganization));
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization != null)
      var isOgAdmin = instituteRoles.find(
        (role) =>
          role.name == "admin" && role.organizationId == selectedOrganization
      );
    if (isOgAdmin) setDisplayLeaders([...leaders]);
    else {
      setSelectedLeader(userSession.userId);
      setDisplayLeaders(leaders.filter((l) => l.id == userSession.userId));
    }
  }, [leaders]);

  useEffect(() => {
    LoadCoursesProgress();
  }, [selectedOrganization, selectedLeader]);

  useEffect(() => {
    if (filter != "")
      setFilteredList(
        assignedCoursesProgress.filter((ac) =>
          ac.name.toLowerCase().includes(filter.toLowerCase())
        )
      );
    else setFilteredList(assignedCoursesProgress);
  }, [filter]);

  useEffect(() => {
    setFilteredList(assignedCoursesProgress);
  }, [assignedCoursesProgress]);

  // Events
  const onchangeOrganization = (o) => {
    setSelectedOrganization(o.target.value);
    LoadCoursesProgress();
  };

  const onchangeLeader = (l) => {
    setSelectedLeader(l.target.value);
  };

  const onSearch = () => {
    setFilter(preFilter);
  };

  const LoadCoursesProgress = () => {
    if (selectedLeader > 0 && selectedOrganization != 0) {
      getCoursesProgress();
    }
  };

  function isValidDate(dateString) {
    // Try to parse the date string
    const date = new Date(dateString);

    // Check if the parsed date is valid
    if (isNaN(date.getTime())) {
      return false; // Invalid date
    }

    // Check if the date matches "December 31, 1969"
    const year = date.getFullYear();
    const month = date.getMonth(); // Months are 0-based (0 = January)
    const day = date.getDate();

    // Return true if it's "December 31, 1969"
    if (year === 1969 && month === 11 && day === 31) {
      return false; // Consider it as "N/A"
    }

    // Otherwise, return true for valid dates
    return true;
  }

  const getDate = (d) => {
    if (isValidDate(d))
      return new Date(d).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    else return "n/a";
  };

  const getCourseTypeDesc = (id) => {
    if (id == 1) return <Translate>course_type_video</Translate>;
    else if (id == 2) return <Translate>course_type_reading</Translate>;
    else if (id == 3) return <Translate>course_type_quiz</Translate>;
    else if (id == 4) return <Translate>course_type_discution</Translate>;
    else if (id == 5) return <Translate>course_download_materials</Translate>;
    else if (id == 5) return <Translate>course_Information</Translate>;
    else if (id == 6) return <Translate>course_link</Translate>;
    else return "n/a";
  };

  const ModuleItemCourseComponent = ({ m }) => {
    const [mainToggle, setMainToggle] = useState(false);
    const toggleContent = () => {
      setMainToggle(!mainToggle); // Toggle the state
    };
    return (
      <>
        <div className="row bkg-papergray border-b-lightgray border-t-accent3 pt-2">
          <div className="col-5 padding-left-50 fw-medium d-flex">
            <div>
            {m.courses?.length > 0 && (
              <button
                className="btn btn-sm btn-flat-accent2 "
                onClick={toggleContent}
              >
                {!mainToggle && (
                  <i className="material-icons text-main right-5">
                    keyboard_arrow_right
                  </i>
                )}
                {mainToggle && (
                  <i className="material-icons text-main right-5">
                    keyboard_arrow_down
                  </i>
                )}
              </button>
            )}
            </div>
            <div>
            {m.courseName}
            </div>
          </div>
          <div className="col-4 text-center">
            <div className="d-flex flex-column">
              <div>
                {m.countCompleted} <Translate>Admin_Out_Of</Translate> {m.total}
              </div>
              <div className="d-flex height-4 bkg-accent3 bottom-4 rounded-2 overflow-hidden">
                <div
                  className={"height-4 width-" + m.progress + "p bkg-success"}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-1 text-center">{m.progress}%</div>
          <div className="col-2 text-right">{getDate(m.lastUpdate)}</div>
        </div>
        <div className="row bkg-accent2 border-b-lightgray border-t-accent3">
          <div className="col-12 padding-left-80">
            {mainToggle && m.courses.length > 0 && (
              <div className="row border-b-lightgray border-t-background bkg-accent4 text-white">
                <div className="col-4 col-md-8  fw-medium">Module</div>
                <div className="col-4 col-md-2 fw-medium">Type</div>
                <div className="col-4 col-md-2 text-right fw-medium">
                  Completed Date
                </div>
              </div>
            )}
            {mainToggle &&
              m.courses.map((c) => {
                return (
                  <div className="row border-b-lightgray border-t-background py-1  bkg-lightgray-light">
                    <div className="col-4 col-md-8 ">{c.name}</div>

                    <div className="col-4 col-md-2 ">
                      {getCourseTypeDesc(c.fkInstituteCourseType)}
                    </div>
                    <div className="col-4 col-md-2 text-right">
                      {getDate(c.completedDate)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const ModuleItemComponent = ({ cp }) => {
    const [mainToggle, setMainToggle] = useState(false);
    const toggleContent = () => {
      setMainToggle(!mainToggle); // Toggle the state
    };
    return (
      <>
        <div className="row border-b-lightgray align-items-lg-baseline border-t-papergray bkg-accent2">
          <div className="col-12 col-md-6 fw-medium text-accent4">
            {cp.coursesProgress?.length > 0 && (
              <button
                className="btn btn-sm btn-flat-accent2 "
                onClick={toggleContent}
              >
                {!mainToggle && (
                  <i className="material-icons text-main right-5">
                    keyboard_arrow_right
                  </i>
                )}
                {mainToggle && (
                  <i className="material-icons text-main right-5">
                    keyboard_arrow_down
                  </i>
                )}
              </button>
            )}
            {cp.name}
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end text-primary">
            <Translate>Enrolled_Courses</Translate>:{" "}
            {cp.coursesProgress?.length}
          </div>
          {/* <div className="col-6 col-md-3 text-right">
        Last Update: Sept 30, 2024
      </div> */}
        </div>
        <caption></caption>

        {mainToggle && cp.coursesProgress?.length > 0 && (
          <div className="mb-4 shadow-2 border-t-papergray">
            <div className="row bkg-accent">
              <div className="col-5 padding-left-50">
                <Translate>main.categorypreview.coursename</Translate>
              </div>
              <div className="col-4 text-center">
                <Translate>Progress</Translate>
              </div>
              <div className="col-1 text-center">%</div>
              <div className="col-2 text-right">
                <Translate>Last_Update</Translate>
              </div>
            </div>

            {mainToggle &&
              cp.coursesProgress?.map((m) => (
                <ModuleItemCourseComponent m={m} />
              ))}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="container py-4 min-vh-100">
        <div className="row">
          <div className="col-12">
            <h1 className="dash my-2">
              <Translate>Course_Progress_Dashboard</Translate>
            </h1>
          </div>
        </div>

        <div className="row border-b-lightgray mb-4"> </div>

        <div className="row">
          <div className="col-12 col-md-5">
            <SmartSelectComponent
              list={organizations}
              label={<Translate>Organization</Translate>}
              display={<Translate>Select_An_Organization</Translate>}
              onChange={onchangeOrganization}
            />
          </div>
          <div className="col-12 col-md-5">
            <SmartSelectComponent
              list={displayLeaders.map((i) => ({
                ...i,
                name: `${i.firstName} ${i.lastName}, ${i.email}`,
              }))}
              label={<Translate>Leader</Translate>}
              display={<Translate>Select_A_Leader</Translate>}
              onChange={onchangeLeader}
            />
          </div>
          <div className="col-12 col-md-2 hidden">
            <button
              className="w-100 btn  btn-flat-secondary d-flex justify-content-center"
              onClick={LoadCoursesProgress}
            >
              <Translate>Filter</Translate>
              <i className="material-icons text-background left-5">tune</i>
            </button>
          </div>
        </div>

        <div className="row border-b-lightgray my-4"> </div>

        <div className="row my-4">
          <div className="col-12 col-md-6">
            <h1 className="dash smaller">
              <Translate>Total_Users</Translate>:{" "}
              {assignedCoursesProgress.length}
            </h1>
          </div>
          <div className="d-flex justify-content-end col-12 col-md-6">
            <div className="position-relative ">
              <input
                type="search"
                className="rounded-5 padding-left-30"
                value={preFilter}
                onChange={(v) => setPreFilter(v.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch();
                  }
                }}
              />
              <div className="position-absolute height-100p top-0 d-flex justify-content-center align-items-center padding-left-5">
                <i className=" material-icons"> search</i>
              </div>
            </div>
            <button
              className="btn btn-sm btn-flat-secondary d-flex align-items-center left-5 rounded-5 px-3"
              onClick={onSearch}
            >
              <Translate>Search</Translate>{" "}
              <i className=" material-icons">search</i>
            </button>
          </div>
        </div>

        {/* <div className="row border-b-lightgray my-4"> </div> */}

        {filteredList.map((cp) => (
          <ModuleItemComponent cp={cp} />
        ))}
      </div>
    </>
  );
};

export default ProgressDashboard;
