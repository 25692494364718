import React, { useRef, useState, useEffect } from "react";
import { ReactReader, ReactReaderStyle } from "react-reader";

import AudioPlayer from "../course-audio-player/AudioPlayer";
import Button from "react-bootstrap/Button";
import Translate from "translate-components";
import CommentModalComponent from "./CommentModal";
import { useSelector } from "react-redux";
import { encodeNumber } from "../../utils/strings";
import Modal from "react-bootstrap/Modal";

const ELTReaderComponent = ({
  pbookmarks,
  phighligths,
  pconfiguration,
  pcourse,
  pupdateBookmarks,
  pupdateHighligts,
  pupdateConfiguration,
  pupdateLastLocation,
  pcompleteCourse,
  psendHighlightsByEmail,
  guideId,
  highlightsWereSentByEmail,
  isVerticalScrollDisplay
}) => {
  const viewerRef = useRef(null);
  const mainRef = useRef(null);
  const [bookUrl, setBookUrl] = useState("");
  const [fontSize, setFontSize] = useState(100);
  const [showOptions, setShowOptions] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);
  const [location, setLocation] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("light");
  const [percent, setPercent] = useState(0);
  const [bookMarks, setBookMarks] = useState([]);
  /*eslint no-unused-vars: ["error", { "args": "none" }]*/
  const [hasChangedBookMarks, setHasChangedBookMarks] = useState(false);
  const [hasChangedConfiguration, setHasChangedConfiguration] = useState(false);
  const [hasChangedHighlights, setHasChangedHighlights] = useState(false);

  //Image Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (src) => {
    setModalImage(src);
    setIsModalOpen(true);
  };

  //Hightlights
  const [selections, setSelections] = useState([]);
  const [bookMarkClass, setBookMarkClass] = useState("optionsBtn");
  const [isCourseCompleted, setIsCourseCompleted] = useState(false);
  const [showType, setShowType] = useState("hights");
  const [updateSelections, setUpdateSelections] = useState(true);
  const [firstLocation, setFirstLocation] = useState("");

  const [sendingEmail, setSendingEmail] = useState(false);
  const lang = useSelector((state) => state.application.selectedLanguageCode);
  const [selectedHighlight, setselectedHighlight] = useState(null);

  const audioUrl = useSelector((state) => state.additionalMedia.mediaUrl);
  const audioLocation = useSelector(
    (state) => state.additionalMedia.mediaLocation
  );
  const audioDuration = useSelector(
    (state) => state.additionalMedia.mediaDuration
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [mapedHighlihts, setMapedHighlihts] =  useState([]);

  const addBookmark = (h) => {
    if(mapedHighlihts.filter((mh) => mh.cfiRange == h.location).length > 0)
     return null;

     try{
      viewerRef.current.annotations.add(
        "highlight",
        h.location,
        {},
        null,
        "hl",
        {
          fill: "red",
          "fill-opacity": "0.5",
          "mix-blend-mode": "multiply",
        }
      );
      const newHighlights = [...mapedHighlihts,{
        text: h.text,
        cfiRange: h.location,
        comment: h.comment,
      }]
      setMapedHighlihts(newHighlights);
    
     } catch (ex) {
      console.log("Eror setting Highlight" + ex);
     }
  }
  const prefillWithParams = () => {
    if (viewerRef.current) {
      //Load Bookmarks
      if (pbookmarks != null && Array.isArray(pbookmarks))
        setBookMarks(pbookmarks.map((b) => b.location));
      //Load Highlights
      if (phighligths != null && Array.isArray(phighligths)) {
          phighligths.map((h) => {
              addBookmark(h);
          });
          setUpdateSelections(false);
    
      }
      //Set the Configuration
      if (pconfiguration != null) {
        if (pconfiguration.fontSize != null)
          setFontSize(pconfiguration.fontSize);
        if (pconfiguration.theme != null) setTheme(pconfiguration.theme);
      }
      if (pcourse != null) {
        if (pcourse.currentReadingLocation != "") {
          var presavedLocation = JSON.parse(pcourse.currentReadingLocation);
          setLocation(presavedLocation);
          setTimeout(() => {
            try {
              viewerRef.current.display(presavedLocation);
            } catch (ex) {
              console.log("Error setting the first Location: " + ex);
            }
          }, 300);
        }
      }
    } else {
      if (pcourse != null) {
        setBookUrl(pcourse.previewVideoUrl);
      }
    }
  };

  useEffect(() => {
    //Check if there is ready to fill with params
    if (guideId == null) prefillWithParams();
  }, [pbookmarks, phighligths, pconfiguration, pcourse]);

  const updateData = (
    phaschangedBookmarks,
    phaschangedHighliths,
    phaschangedConfiguration
  ) => {
    if (phaschangedBookmarks) {
      pupdateBookmarks(bookMarks);
      setHasChangedBookMarks(false);
    }
    if (phaschangedHighliths) {
      pupdateHighligts(selections);
      setHasChangedHighlights(false);
    }
    if (phaschangedConfiguration) {
      pupdateConfiguration({ fontSize, theme: selectedTheme });
      setHasChangedConfiguration(false);
    }
  };

  //Sincronize The new bookmarks and Higthlights
  useEffect(() => {
    updateData(
      hasChangedBookMarks,
      hasChangedHighlights,
      hasChangedConfiguration
    );
  }, [
    bookMarks,
    selections,
    fontSize,
    selectedTheme,
    location,
    hasChangedHighlights,
  ]);
  const miStorage = window.localStorage;

  useEffect(() => {
    if (guideId == null) {
      if (viewerRef.current) {
        viewerRef.current.on("selected", setRenderSelection);
        // viewerRef.current.on("markClicked", (cfi, data, contents) => {
        //   if (cfi != null) {
        //     var customHighlight = selections.filter((s) => s.cfiRange == cfi);
        //     if (customHighlight) console.log(JSON.stringify(customHighlight));
        //   }
        // });

        return () => {
          viewerRef.current.off("selected", setRenderSelection);
        };
      }
    }
  }, [setSelections, selections]);

  useEffect(() => {
    ///Hide the Prev and Next Arrows by the Scrolling Type
    if(isVerticalScrollDisplay){
      const navButtons = document.querySelectorAll(
        'button[style*="position: absolute"][style*="font-size: 90px"]'
      );
      navButtons.forEach((button) => {
        button.style.display = "none";
      });
    }

  }, []);

  useEffect(() => {
    if (guideId == null) {
      if (updateSelections == true) {
        selections.forEach((sel) => {
          addBookmark(sel);
        });

        setUpdateSelections(false);
        if (viewerRef.current != null) {
          try {
            viewerRef.current.display(location);
          } catch (ex) {
            console.log("Error setting the Location: " + location);
          }
        }
      }
    }
  }, [updateSelections]);

  useEffect(() => {
    if (guideId == null) {
      if (viewerRef.current) {
        viewerRef.current.themes.fontSize(`${fontSize}%`);
        setHasChangedConfiguration(true);
      }
    }
  }, [fontSize]);

  useEffect(() => {
    if (guideId == null) {
      //Update bookmark style
      setBookMarkClass(hasBookMark());
    }
  }, [bookMarks]);

  useEffect(() => {
    if (guideId != null) {
      setBookUrl(
        process.env.REACT_APP_BRAIN_MODEL_GUIDE_URL.replace(
          "{1}",
          guideId
        ).replace("{0}", lang)
      );
    }
  }, [guideId]);

  useEffect(() => {
    setSendingEmail(false);
  }, [highlightsWereSentByEmail]);

  const tocRef = useRef(null);
  const locationChanged = () => {
    if (viewerRef.current && tocRef.current) {
      const { cfi, displayed } = viewerRef.current.location.end;
      if (firstLocation == "") {
        setFirstLocation(cfi);
      }
      setLocation(cfi);

      const completion =
        Math.round(displayed.page * 100) / (displayed.total - 1);

      setPageNumber(displayed.page);
      setPercent(Math.min(completion, 100).toFixed(0));

      setBookMarkClass(hasBookMark());
      if (completion >= 90 && !isCourseCompleted) {
        pcompleteCourse();
        setIsCourseCompleted(true);
      }

      if (guideId == null) {
        //Persiste the last location
        pupdateLastLocation(cfi);
      }
    }
  };

  const changeBookMark = () => {
    const { cfi } = viewerRef.current.location.start;
    if (bookMarks.findIndex((b) => b == cfi) < 0) {
      const newBookMarks = [cfi].concat(bookMarks);

      setBookMarks(newBookMarks);
    } else {
      const newBookMarks = bookMarks.filter((b) => b != cfi);
      setBookMarks(newBookMarks);
    }
    setBookMarkClass(hasBookMark());
    setHasChangedBookMarks(true);
  };

  const hasBookMark = () => {
    let result = false;
    if (
      viewerRef.current &&
      tocRef.current &&
      viewerRef.current.location != null
    ) {
      const { cfi } = viewerRef.current.location.start;
      result = bookMarks.findIndex((b) => b == cfi) >= 0;
    }
    return result ? "optionsBtn bookmarked" : "optionsBtn";
  };

  const setRenderSelection = (cfiRange, contents) => {
    const text = viewerRef.current.getRange(cfiRange).toString();
    contents.window.getSelection().removeAllRanges();
    const newHighlight = {
      text: text,
      cfiRange,
      comment: "",
    };
    setselectedHighlight(newHighlight);
  };

  const onSaveHighlight = (comment) => {
    const newHighlight = selectedHighlight;
    const previousExist = selections.filter(
      (s) => s.cfiRange == selectedHighlight.cfiRange
    );
    selectedHighlight.comment = comment;
    if (previousExist.length == 0)
      setSelections(selections.concat(newHighlight));

    viewerRef.current.annotations.add(
      "highlight",
      newHighlight.cfiRange,
      {},
      null,
      "hl",
      {
        fill: "red",
        "fill-opacity": "0.5",
        "mix-blend-mode": "multiply",
      }
    );
    const newHighlights = [...mapedHighlihts,{
      text: newHighlight.text,
      cfiRange: newHighlight.cfiRange,
      comment: newHighlight.comment,
    }]
    setMapedHighlihts(newHighlights);
    viewerRef.current.annotations.mark(newHighlight.cfiRange, {}, null);

    setShowOptions(false);
    setShowHighlight(false);
    setHasChangedHighlights(true);
    miStorage.setItem("hightlights", selections);
    setselectedHighlight(null);
  };

  const darkModeTheme = {
    h1: { color: "#EFEFEF" },
    h2: { color: "#EFEFEF" },
    h3: { color: "#EFEFEF" },
    h4: { color: "#EFEFEF" },
    h5: { color: "#EFEFEF" },
    p: {
      color: "#EFEFEF",
    },
    span: {
      color: "#EFEFEF",
    },
    body: {
      background: "#1A1A1A",
    },
  };
  const ligthModeTheme = {
    h1: { color: "#000" },
    h2: { color: "#000" },
    h3: { color: "#000" },
    h4: { color: "#000" },
    h5: { color: "#000" },
    p: {
      color: "#000",
    },
    span: {
      color: "#000",
    },
    body: {
      background: "#FFF",
    },
  };
  const sepiaModeTheme = {
    h1: { color: "#704214" },
    h2: { color: "#704214" },
    h3: { color: "#704214" },
    h4: { color: "#704214" },
    h5: { color: "#704214" },
    p: {
      color: "#704214",
    },
    span: {
      color: "#704214",
    },
    body: {
      background: "#ffeacb",
    },
  };

  const setTheme = (theme) => {
    setLocation("");
    setSelectedTheme(theme);
    viewerRef.current.themes.select(theme);
    setHasChangedConfiguration(true);
  };

  const decreaseFont = () => {
    viewerRef.current.display(firstLocation);

    clearHighlights();
    const newSize = Math.max(100, fontSize - 30);
    setFontSize(newSize);

    setHasChangedConfiguration(true);
  };

  const incrementFont = () => {
    viewerRef.current.display(firstLocation);

    clearHighlights();
    const newSize = Math.min(300, fontSize + 30);
    setFontSize(newSize);
    setHasChangedConfiguration(true);
  };

  const clearHighlights = () => {
    selections.forEach((el) => {
      viewerRef.current.annotations.remove(el.cfiRange, "highlight");
    });
    setUpdateSelections(true);
  };

  const existAudiobook = () => {
    if (
      pcourse.additionalMedia != undefined &&
      pcourse.additionalMedia !== null
    ) {
      let element = pcourse.additionalMedia.find(
        (element) => element.fkInstituteAdditionalMediaType === 1
      );
      if (element !== undefined) {
        return true;
      }
    }

    return false;
  };

  const ownStyles = {
    ...ReactReaderStyle,
    readerArea: {
      ...ReactReaderStyle.readerArea,
      backgroundColor:
        selectedTheme == "sepia"
          ? "#ffeacb"
          : selectedTheme == "dark"
          ? "#1A1A1A"
          : "#FFF",
    },
    arrow: {
      ...ReactReaderStyle.arrow,
      height: "100%",
      top: 0,
      fontSize: "90px",
      fontWeight: "normal",
      color: "rgb(127 131 127)",
    },
    arrowHover: {
      ...ReactReaderStyle.arrowHover,
      backgroundColor: "rgb(127 131 127)",
      color: "white",
    },
  };

  return (
    <div className="readingContainer">
      <div className="optionsContainer">
        {/* {guideId == null && ( */}
        <a href={"/course/" + encodeNumber(pcourse.fkInstituteModule)+"#"+encodeNumber(pcourse.id)} className="exitBtn">
          <span className="material-icons">arrow_back</span>{" "}
          <Translate>backtocourse</Translate>
        </a>
        {/* )} */}
        {guideId == null && (
          <div>
            {existAudiobook() && audioUrl != "" && (
              <AudioPlayer
                audioSource={audioUrl}
                audioTitle={""}
                audioArtist="TGR Institute"
                audioLocation={audioLocation}
                audioDuration={audioDuration}
                completeCourse={() => {}}
                mustCompleteCourse={false}
                isPlugin={true}
                audioClassName="optionsBtn"
              />
            )}
            <div
              onClick={() => {
                setShowHighlight(false);
                setShowOptions(!showOptions);
              }}
              className="optionsBtn"
            >
              <span className="material-icons">format_size</span>
            </div>

            <div className={bookMarkClass} onClick={changeBookMark}>
              <span className="material-icons">bookmark</span>
            </div>
            {selections.length > 0 && (
              <div
                className="optionsBtn"
                onClick={() => setShowHighlight(!showHighlight)}
              >
                <span className="material-icons">list</span>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className="manual-container"
        style={{ background: "red!important" }}
        ref={mainRef}
      >
        <ReactReader
          url={bookUrl}
          showToc={false}
          // ref={viewerRef}
          viewerStyle={{ fontSize: fontSize }}
          styles={ownStyles}
          onBookInfoChange={(e) => {
            //setLocation(e);
            setLocation(viewerRef.current.getCurrentCfi());
          }}
          tocChanged={(toc) => (tocRef.current = toc)}
          locationChanged={locationChanged}
          epubOptions={
            //Verticall Display Configuration
            isVerticalScrollDisplay?{ 
              allowScriptedContent: true,
              flow: 'scrolled', // Enable vertical scrolling
              manager: 'continuous', // Allows continuous scrolling
           }:{
            allowScriptedContent: true,
           }}
          getRendition={(rendition) => {
            viewerRef.current = rendition;
            //Themes
            viewerRef.current.themes.register("ligth", ligthModeTheme);
            viewerRef.current.themes.register("dark", darkModeTheme);
            viewerRef.current.themes.register("sepia", sepiaModeTheme);
            //Font Size
            viewerRef.current.themes.fontSize(`${fontSize}%`);
            viewerRef.current.themes.default({
              "::selection": {
                background: "orange",
              },
            });
            setSelections([]);
            //When the book is ready try to fill with params
            if (guideId == null) prefillWithParams();

            rendition.hooks.content.register((contents) => {
              contents.document.addEventListener("click", (event) => {
                if (event.target.tagName === "IMG") {
                  event.preventDefault();
                  const src = event.target.getAttribute("src");
                  console.log("Image clicked", src);
                  handleImageClick(src);
                }
              });
            });
          }}
        />
      </div>
      {showOptions && (
        <div className="optionsCard">
          <div className="text-center">
            <Translate>reading.highligts.font.size</Translate>
          </div>
          <div className="fontBtnContainer">
            <div className="btnFontSm" onClick={decreaseFont}>
              <span className="material-icons">title</span>
            </div>
            <div className="btnFontLg" onClick={incrementFont}>
              <span className="material-icons">title</span>
            </div>
          </div>
          <div className="text-center">
            <Translate>reading.highligts.theme</Translate>
          </div>
          <div className="fontBtnContainer">
            <div
              className={
                selectedTheme == "ligth" ? "themBtn1 selected" : "themBtn1"
              }
              onClick={() => setTheme("ligth")}
            ></div>
            <div
              className={
                selectedTheme == "sepia" ? "themBtn2 selected" : "themBtn2"
              }
              onClick={() => setTheme("sepia")}
            ></div>
            <div
              className={
                selectedTheme == "dark" ? "themBtn3 selected" : "themBtn3"
              }
              onClick={() => setTheme("dark")}
            ></div>
          </div>
        </div>
      )}

      {showHighlight && (
        <div className="optionsCard">
          <div className="optionsHeader">
            <div className="titlee">
              <Translate>goto</Translate>
            </div>
            <button
              className="removeHighlight"
              onClick={() => setShowHighlight(false)}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
          <div className="optionsNav">
            <button
              className={showType == "hights" ? "selected" : ""}
              onClick={() => setShowType("hights")}
            >
              <Translate>reading.highligts.highlights</Translate>
            </button>
            <button
              className={showType == "notes" ? "selected" : ""}
              onClick={() => setShowType("notes")}
            >
              <Translate>reading.highligts.notes</Translate>
            </button>
            <button
              className={showType == "books" ? "selected" : ""}
              onClick={() => setShowType("books")}
            >
              <Translate>reading.highligts.bookmarks</Translate>
            </button>
          </div>
          {showType == "hights" && (
            <>
              <ul>
                {selections
                  .filter((s) => s.comment == null || s.comment == "")
                  .map((pnote, i) => (
                    <li key={i}>
                      <div
                        onClick={() => {
                          viewerRef.current.display(pnote.cfiRange);
                        }}
                        className="hLocation"
                      >
                        {pnote.text.substring(
                          0,
                          Math.min(25, pnote.text.length)
                        ) + (pnote.text.length > 25 ? "..." : "")}
                      </div>
                      {/* <button
                        className="commentHighlight"
                        onClick={() => setselectedHighlight(selections[i])}
                      >
                        <span
                          className={
                            comment == null || comment == ""
                              ? "material-icons"
                              : "material-icons bookmarked"
                          }
                        >
                          note
                        </span>
                      </button> */}
                      <button
                        className="removeHighlight"
                        onClick={() => {
                          viewerRef.current.annotations.remove(
                            pnote.cfiRange,
                            "highlight"
                          );
                          setSelections(
                            selections.filter((item) => item !== pnote)
                          );
                          setHasChangedHighlights(true);
                        }}
                      >
                        <span className="material-icons">close</span>
                      </button>
                    </li>
                  ))}
              </ul>
              {selections.filter((s) => s.comment == null || s.comment == "")
                .length > 0 && (
                <div className="optionsFooter">
                  {sendingEmail ? (
                    <span>
                      <Translate>reading.highligts.sending</Translate>
                    </span>
                  ) : (
                    <Button
                      className="btn-success"
                      onClick={() => {
                        setSendingEmail(true);
                        psendHighlightsByEmail(
                          selections.filter(
                            (s) => s.comment == null || s.comment == ""
                          )
                        );
                      }}
                    >
                      <Translate>reading.send.highligts</Translate>
                    </Button>
                  )}
                </div>
              )}
            </>
          )}

          {showType == "notes" && (
            <>
              <ul>
                {selections
                  .filter((s) => s.comment != null && s.comment != "")
                  .map((pnote, i) => (
                    <li key={i}>
                      <div
                        onClick={() => {
                          viewerRef.current.display(pnote.cfiRange);
                        }}
                        className="hLocation"
                      >
                        {pnote.comment.substring(
                          0,
                          Math.min(25, pnote.comment.length)
                        ) + (pnote.comment.length > 25 ? "..." : "")}
                      </div>
                      <button
                        className="commentHighlight"
                        onClick={() => setselectedHighlight(pnote)}
                      >
                        <span className={"material-icons"}>note</span>
                      </button>
                      <button
                        className="removeHighlight"
                        onClick={() => {
                          viewerRef.current.annotations.remove(
                            pnote.cfiRange,
                            "highlight"
                          );
                          setSelections(
                            selections.filter((item, j) => item !== pnote)
                          );
                          setHasChangedHighlights(true);
                        }}
                      >
                        <span className="material-icons">close</span>
                      </button>
                    </li>
                  ))}
              </ul>
              {selections.filter((s) => s.comment != null && s.comment != "")
                .length > 0 && (
                <div className="optionsFooter">
                  {sendingEmail ? (
                    <span>
                      <Translate>reading.highligts.sending</Translate>
                    </span>
                  ) : (
                    <Button
                      className="btn-success"
                      onClick={() => {
                        setSendingEmail(true);
                        psendHighlightsByEmail(
                          selections.filter(
                            (s) => s.comment != null && s.comment != ""
                          )
                        );
                      }}
                    >
                      <Translate>reading.send.notes</Translate>
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
          {showType == "books" && (
            <ul>
              {bookMarks.reverse().map((b, i) => (
                <li key={i}>
                  <div
                    onClick={() => {
                      viewerRef.current.display(b);
                    }}
                    className="hLocation"
                  >
                    <Translate>bookmark</Translate> {i + 1}
                  </div>
                  <button
                    className="removeHighlight"
                    onClick={() => {
                      setBookMarks(bookMarks.filter((j) => j !== b));
                      setHasChangedBookMarks(true);
                    }}
                  >
                    <span className="material-icons">close</span>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      <div className="pageLocation">
        <div className="progressBar">
          <div className="progressMade" style={{ width: percent + "%" }}></div>
        </div>

        <div className="progressPercent">
          <Translate>Page</Translate>
          <span>{pageNumber}</span>,<span>{percent}%</span>
        </div>
        {/* <div>
          <span>{pageNumber}</span>
        </div> */}
        <span className="hidden">Location: {location}</span>
      </div>

      <CommentModalComponent
        highlight={selectedHighlight}
        show={selectedHighlight !== null}
        onCancel={() => setselectedHighlight(null)}
        onSave={onSaveHighlight}
      ></CommentModalComponent>
      <Modal
        size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        dialogClassName="image-zoom-modal" // Apply custom styles to the modal
        
      >
      <Modal.Body    style={{
      //display: "flex", // Flexbox for alignment
      //justifyContent: "center", // Center content horizontally
      //alignItems: "center", // Center content vertically
      //padding: "0", // Remove padding for better fit
      //height: "80vh", // Standard modal height
      //width: "80vw", // Standard modal width
    }}>
      <div className="width-100p">
        <div className="modalCloseBtn "
        onClick={() => setIsModalOpen(false)}>
          <i className="material-icons text-background">close</i>
        </div>
        <img
          src={modalImage}
          alt="EPUB content"
          style={{
            width: "100%", // Fit the image within modal width
            // Height: "100%", // Fit the image within modal height
            objectFit: "contain", // Ensure proportional scaling
          }}
        />
      </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center width-100p">
        <Button  
          className="btn-danger"
          onClick={() => setIsModalOpen(false)}>
          <Translate>close</Translate>
        </Button>
        </div>
      </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ELTReaderComponent;
